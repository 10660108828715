@import 'assets/css/constants.scss';
@import 'assets/css/fonts.scss';

* {
  font-family: Avenir, Arial, Helvetica, sans-serif !important;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  outline: none;

  body {
    overflow: hidden;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    #app {
      overflow: hidden;
      height: calc(100vh - $topbar-height);
      height: calc(100svh - $topbar-height);
      margin-top: $topbar-height;
    }
  }
}

h2 {
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
}

h3 {
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
}

.page {
  height: calc(100vh - $topbar-height);
  height: calc(100svh - $topbar-height);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hide-scroll {
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 12px;
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    padding: 1px 0;
    background: #eeeeee;
  }
  
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 0;
    background: #bbbbbb;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style: none;
}

.underline {
  text-decoration: underline;
}

button {
  outline: none;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}
