.input-wrapper.select {
  position: relative;

  select {
    cursor: pointer;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &:disabled {
      cursor: not-allowed;
      background-color: #f5f5f5;
      color: #767676;
    }
  }

  svg, .loader {
    position: absolute;
    width: 24px;
    right: 8px;
    bottom: 11px;
  }

  &.input--small {
    svg, .loader {
      width: 22px;
      bottom: 8px;
    }
  }

  &.input--x-small {
    svg, .loader {
      width: 20px;
      bottom: 6px;
    }
  }
}