@import 'assets/css/constants.scss';

.modal-add-members {
  min-width: 600px;

  h2 {
    color: $color-main;
  }

  table.users {
    border-collapse: collapse;
    width: 100%;

    tr:hover {
      background-color: #F5F9FF;
      cursor: pointer;
    }

    td {
      padding: 8px;

      &.cp {
        font-weight: 800;
        font-size: 14px;
      }

      &.name {
        color: $color-main;
        font-size: 12px;
      }

      &.email {
        font-size: 12px;
      }
    }

    &.highlighted {
      tr {
        background-color: #F5F9FF;
        border-bottom: 5px solid white;

        td {

          &:first-of-type {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          &:last-of-type {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          &.remove {
            svg {
              transform: scale(.7);
            }
          }
        }
        
        &:hover {
          cursor: initial;
        }

        &.show-more {
          background-color: white;
          
          td {
            padding: 0 8px;
          }
        }
      }
    }
  }

  .search {
    position: relative;
    z-index: 1;

    .results {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 6px;
      top: 49px;
      max-height: 400px;
      background-color: white;
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      width: 100%;

      table {
        border-collapse: collapse;
        tr:hover {
          background-color: #F5F9FF;
          cursor: pointer;
        }
        td {
          padding: 8px;

          &.cp {
            font-weight: 800;
            font-size: 14px;
          }

          &.name {
            color: $color-main;
            font-size: 12px;
          }

          &.email {
            font-size: 12px;
          }
        }
      }
    }
  }

  .groups {
    width: 100%;
    gap: 24px;

    div {
      flex: 1;
    }
  }
}