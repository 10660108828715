@import 'assets/css/constants.scss';

.collection-title.panel-title {
  max-width: 650px;

  .title-container {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  p.title {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  svg:last-of-type {
    cursor: pointer;
    flex-shrink: 0;
  }
}

.collection-panel {
  padding: 16px;
  align-self: flex-start;
  background-color: white;
  width: 650px;

  & ~ svg {
    z-index: 1;
  }
  
  .title {
    gap: 8px; 
  }
  
  p.code-geo, p.ouranos-id {
    font-size: 12px;
  }

  .separator {
    width: 100%;
    height: 2px;
    background: #EEF3FB;
    margin: 16px 0;
  }

  .small-separator {
    width: calc(100% - 64px);
    height: 1px;
    background: #EEF3FB;
    margin: 8px 32px 8px;
  }

  .accordion {
    > h3 {
      color: $color-main;
      font-weight: 800;
      font-size: 18px;
    }

    > svg {
      fill: $color-main;
    }

    .is-item {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .encadrants {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      button {
        padding: 2px 4px;
        background-color: #374459;
        color: white;
        font-size: 14px;
        border-radius: 4px;
      }
    }

    table td {
      padding: 2px 8px;

      &.c6-field {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .linked-works .accordion {
    & > h3 {
    color: #000000;
    font-size: 16px;
    }

    & > svg {
      fill: #000000;
    }
  }

  table {
    border-collapse: collapse;
    th {
      text-align: left;
      padding: 0 8px;
    }

    tr.interactive {
      cursor: pointer;

      &:hover {
        background: #EEF3FB;
      }
    }
  }
}