.team-members-modal {
  display: flex;
  flex-direction: column;
  gap: 32px;

  table.members {
    border-collapse: collapse;
    thead th {
      border: 1px solid #E2E7EF;
      text-align: left;
      padding: 21px 16px;
    }

    tbody td {
      padding: 8px 16px;
      color: #242830;

      span.role {
        background: #E0F9E1;
        padding: 6px 12px;
        border-radius: 24px;

        &.leader {
          background: #FFF5BC;
        }
      }
    }
  }
}