.select-check.input-wrapper {
  position: relative;
  cursor: pointer;

  input[type=text] {
    cursor: pointer;
    pointer-events: none;
  }

  .options {
    border-bottom: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
    border-left: 1px solid #d5d5d5;
    border-top: 1px solid #f4f4f4;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: white;
    position: absolute;
    top: 62px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    right: 0;
    width: 100%;
    z-index: 1;
    max-height: 147px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.11s ease-in;

    .checkbox {
      padding: 4px 8px;

      &:hover {
        background: #F5F5F5;
      }
    }

    &.hidden {
      max-height: 0;
      opacity: 0;
    }
  }
  
  .chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    background: white;
    transition: all 0.1s ease-in;
    position: relative;
    cursor: default;
    padding-right: 27px;
    padding: 8.5px 27px 8.5px 8.5px;
    flex-direction: row;

    .chip {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      padding: 0px 5px;
      border: 1px solid #d5d5d5;
      border-radius: 5px;
      background: #F5F5F5;
      font-size: 12px;
      color: #666666;
      cursor: pointer;

      svg {
        width: 10px;
      }
    }

    > svg {
      position: absolute;
      width: 24px;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }
}