@import 'assets/css/constants.scss';

.modal-code-select {
  position: relative;
  min-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .search {
    position: relative;
    z-index: 1;

    .results {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 6px;
      max-height: 400px;
      width: 100%;
      background-color: white;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;

      table {
        border-collapse: collapse;
        tr:hover {
          background-color: #F5F9FF;
          cursor: pointer;
        }
        td {
          padding: 8px;

          &.code {
            font-weight: 800;
            font-size: 14px;
          }

          &.name {
            color: $color-main;
            font-size: 12px;
          }

          &.astre {
            font-size: 12px;
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    justify-content: center;
  }
}