@import 'assets/css/constants.scss';

.modal-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;

  .modal {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    max-height: 90vh;
    max-height: 90svh;
    gap: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 998;
    min-width: 320px;
    background-color: #fff;
    border-radius: 6px;
    padding: 24px;

    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      align-items: center;

      > h2 {
        color: $color-main;
      }

      > p {
        color: #212731;
        font-size: 16px;
        line-height: 22px;
        max-width: 442px;
        text-align: center;
      }
    }

    .actions {
      display: flex;
      gap: 24px;
      justify-content: center;

      button {
        max-width: 212px;
        width: 100%;
      }
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.8);
  }

  &.displayed {
    display: block;
  }
}