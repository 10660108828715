@import 'assets/css/constants.scss';

.users-panel-title.panel-title {
  max-width: 1200px;
}

button.create-team {
  width: fit-content;
  margin: 12px 24px 12px auto;
}

.users-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1200px;

  .text.input-wrapper {
    margin: 15px 24px 24px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 13px;

    thead tr {
      position: sticky;
      top: 0;
      z-index: 2;
    }

    th {
      background: #212731;
      color: white;
      font-weight: 600;
      text-align: left;
      padding: 8px 16px;

      &:has(div.teams) {
        width: 200px;
      }

      &:has(div.actions) {
        width: 75px;
      }

      &:not(:last-of-type) {
        border-right: 1px solid #E2E7EF;
      }
    }

    td {
      padding: 8px 16px;

      &:has(div.teams) {
        width: 100px;
      }
      
      div.teams {
        display: flex;
        flex-direction: column;
        gap: 2px;

        button {
          margin-right: auto;
          color: #256EFF;
        }

        /* ellipsis */
        span {
          max-width: 165px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span.role {
        background: #E0F9E1;
        padding: 2px 8px;
        border-radius: 6px;
        font-size: 10px;
        font-weight: 800;

        &.moe {
          background: #FFF5BC;
        }

        &.validator {
          background: #FFD6A5;
        }

        &.observer {
          background: #e7d5fb;
        }
      }
    }

    tr:nth-child(odd) {
      background: #F5F9FF;
    }
  }

  .loader {
    margin: auto;
  }
}

@media screen and (max-width: 915px) {
  .users-panel table {
    font-size: 12px;

    td {
      padding: 4px 8px;
    }
  }
}