@import 'assets/css/constants.scss';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
  margin: 8px 0;

  .pagination-button, .pagination-number {
    padding: 4px 6px;
    width: 30px;
    border: 1px solid #ebeaea;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
  }

  .pagination-button:disabled,
  .pagination-number.active {
    background-color: $color-main;
    color: white;
    cursor: not-allowed;
  }

  .pagination-button:disabled {
    opacity: 0.5;
  }

  .pagination-button:hover:not(:disabled),
  .pagination-number:hover {
    background-color: $color-secondary;
  }
}
