@import 'assets/css/constants.scss';

.modal-user-create {
  width: 600px;

  h2 {
    color: $color-main;
  }

  .search {
    position: relative;
    z-index: 1;

    .results {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 6px;    top: 69px;
      max-height: 400px;
      background-color: white;
      border-radius: 5px;
      border: 1px solid #d5d5d5;
      width: 100%;

      table {
        border-collapse: collapse;
        tr:hover {
          background-color: #F5F9FF;
          cursor: pointer;
        }
        td {
          padding: 8px;

          &.cp {
            font-weight: 800;
            font-size: 14px;
          }

          &.name {
            color: $color-main;
            font-size: 12px;
          }

          &.email {
            font-size: 12px;
          }
        }
      }
    }
  }

  .groups {
    align-items: flex-start;
    display: flex;
    width: 100%;
    gap: 24px;

    div {
      flex: 1;
    }
  }
}