@import 'assets/css/constants.scss';

.drop-menu {
  position: relative;
  & > ul {
    position: absolute;
    top: 35px;
    right: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 5px -3px, rgba(0, 0, 0, 0.1) 0px 8px 10px 1px, rgba(0, 0, 0, 0.08) 0px 3px 14px 2px;
    border-radius: 4px;
    flex-direction: column;
    opacity: 0;
    transition: all 0.2s ease;
    pointer-events: none;
    overflow: hidden;
    gap: 8px;
    z-index: 999;

    li {
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      &:hover {
        background-color: #F5F5F5;
      }

      a {
        padding: 8px 16px;
        color: black;
        text-decoration: none;
        font-weight: 400;
        gap: 10px;
        white-space: nowrap;

        svg {
          height: 20px;
        }
      }
    }

    &.displayed {
      top: 30px;
      opacity: 1;
      pointer-events: all;
    }
  }

  .settings-button svg path {
    fill: $color-main;
  }
}   